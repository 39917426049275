import { useForm } from "react-hook-form";
import TableUploadRow from "../../component/TableUploadRow";
import TimeBadge from "../../component/TimeBadge";
import Dropzone from "../../component/Dropzone";
import { TFormData } from "../../types";
import axios from "axios";
import { useImperativeHandle, useRef, useState } from "react";
import useAutosizeTextArea from "../../component/AutoResizeTextArea";
import { Helmet } from "react-helmet";

export default function CreatePage() {

  const { 
    register,
    handleSubmit, 
    control, 
    watch, 
    getValues, 
    setValue, 
    formState: { isLoading, isSubmitting } 
  } = useForm<TFormData>({
    defaultValues: {
      time: "30",
      password: "",
      content: "",
      files: [],
    },
  });

  const [progress, setProgress] = useState<number>(0)
  const [errors, setErrors] = useState<{ msg: string; }[]>([])
  const [isProcessing, setIsProcessing] = useState<boolean>(false)

  const onSubmit = async (data: TFormData) => {
    setIsProcessing(true)
    const formData = new FormData()

    if (data.password) {
      formData.append('password', data.password)
    }

    if (data.content) {
      formData.append('content', data.content)
    }

    for(let i = 0; i < data.files.length; i++) {
      formData.append('files', data.files[i])
    }

    formData.append('time', data.time)

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACK_HOST}/api/v1/store`, 
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data;charset=utf-8',
            'Accept-Encoding': 'UTF-8'
          },
          onUploadProgress: (event) => {
            setProgress(Math.round(100 * event.loaded) / (event.total || 1))
          }
        },
      )

      if (response.status >= 200 && response.status < 300 && response.data.alias) {
        window.location.href = `/${response.data.alias}`;
      }

    } catch (error: any) {
      setIsProcessing(false)

      if (axios.isAxiosError(error)) {
        if (error.response?.status === 422) {
          const errorsResponse: { msg: string }[] = error.response.data.errors;
          setErrors(errorsResponse)
        }
      } else {
        console.error(error)
      }
    }
  };

  const watchUploadFiles = watch("files", []);

  const limit: number = parseInt(process.env.REACT_APP_LIMIT!);

  const handeRemoveFile = (index: number) => {
    const files = getValues('files');
    setValue('files', files.filter((file, i) => i !== index))
  }

  const watchContentValue = watch("content", '');
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  useAutosizeTextArea(textAreaRef.current, watchContentValue);

  const { ref, ...registerFormContent } = register("content", { required: false, maxLength: 10240 })

  useImperativeHandle(ref, () => textAreaRef.current)

  const times = [
    {
      label: '5 mins',
      value: 5
    },
    {
      label: '15 mins',
      value: 15
    },
    {
      label: '30 mins',
      value: 30
    },
    {
      label: '1 hour',
      value: 60
    },
    {
      label: '3 hours',
      value: 180
    },
    {
      label: '8 hours',
      value: 480
    },
  ]

  return (
    <>
      <Helmet>
        <title>DO Share | Create new store</title>
        <meta name="description" content="Easily upload files and add text on this page. Create a unique link to share your content securely and temporarily. Quick and straightforward process with a user-friendly interface." />
      </Helmet>

      <div className="relative">
        <div className="py-6">
          <form onSubmit={handleSubmit(onSubmit)} acceptCharset="UTF-8">
            <div className="max-w-3xl m-auto px-5 grid gap-4 grid-cols-1 relative">

              {errors.length > 0 && (
                errors.map((x, index) => {
                  return (
                    <div 
                      key={index} 
                      className="p-4 text-red-800 rounded-lg bg-red-50" role="alert"
                    >
                      <span className="font-bold">Error !</span> {x.msg}
                    </div>
                  )
                })
              )}

              {(isProcessing || isSubmitting) && (
                <div className="absolute w-full h-full bg-white opacity-85">
                  <div className="relative -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2 w-9/12">
                    <div className="text-center text-2xl p-6">{Math.round(progress)}%</div>
                    <div className="w-full bg-gray-200 rounded-full h-2.5">
                      <div className="bg-slate-600 h-2.5 rounded-full" style={{ width: `${progress}%`}}></div>
                    </div>
                  </div>
                </div>
              )}

              <div>
                <input
                  type="text"
                  {...register("password", { required: false, maxLength: 64 })}
                  className="block w-full p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-base focus:ring-slate-500 focus:border-slate-500"
                  placeholder="Password (not required)"
                  disabled={isLoading || isSubmitting}
                />
              </div>

              {times.length > 0 && (
                <div className="grid md:grid-cols-6 grid-cols-3 gap-2">
                  {times.map((x) => {
                    return (<TimeBadge
                      label={x.label}
                      value={x.value.toString()}
                      control={control}
                      name="time"
                      disabled={isLoading || isSubmitting}
                    />)
                  })}
                </div>
              )}

              <div>
                <textarea
                  {...registerFormContent}
                  ref={textAreaRef}
                  style={{height: '200px'}}
                  className="block p-4 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-slate-500 focus:border-slate-500"
                  placeholder="Write your thoughts here..."
                  disabled={isLoading || isSubmitting}
                ></textarea>
              </div>

              <Dropzone 
                control={control} 
                disabled={isLoading || isSubmitting} 
                limit={limit}
              />

              {watchUploadFiles.length > 0 && (
                <div className="overflow-x-auto border border-gray-300 rounded-lg">
                  <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                      <tr>
                        <th scope="col" className="px-4 py-3">
                          File
                        </th>
                        <th scope="col" className="px-4 py-3 text-end">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {watchUploadFiles.map((file, key) => {
                        return (
                          <TableUploadRow
                            key={key}
                            title={file.name}
                            size={file.size}
                            disabled={isLoading || isSubmitting}
                            remove={() => handeRemoveFile(key)}
                          />
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              )}

              <div>
                <button
                  type="submit"
                  id="create-store-submit-button"
                  className="w-full border-1 text-lg font-bold rounded-lg px-4 py-3 bg-slate-600 text-white text-center"
                  disabled={isLoading || isSubmitting}
                >
                  STORE
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

    </>
  );
}
